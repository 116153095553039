import React from 'react';
import HeaderLink from '../HeaderLink/HeaderLink';
import styles from './Header.module.css';

const Header = () => {
  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <HeaderLink 
          to="/" 
          icon="/hat.png" 
          text="mardu.cc" 
          size="large"
        />
      </div>
      <nav className={styles.nav}> 
        <HeaderLink 
          to="/" 
          text="Home" 
          size="large"
        />
        <HeaderLink 
          to="/projects" 
          text="Projects" 
          size="large"
        />
        <HeaderLink 
          to="/playground" 
          text="Playground" 
          size="large"
        />
      </nav>
      <div className={styles.githubContainer}>
        <HeaderLink 
          to="https://github.com/mardukpill" 
          icon="/github-logo.png" 
          text="GitHub" 
          external
          size="medium"
        />
      </div>
    </header>
  );
};

export default Header;
