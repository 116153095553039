import React from 'react';
import Layout from '../../common/Layout/Layout';
import Clock from '../../common/Clock/Clock';
import Divider from '../../common/Divider/Divider';
import useFadeEffect from '../../hooks/useFadeEffect';
import styles from './Home.module.css';

const Home = () => {
  const isVisible = useFadeEffect();

  return (
    <Layout>
      <div className={`${styles.homeContent} fade-effect ${isVisible ? 'visible' : ''}`}>
        <h2>Welcome</h2>
        <Divider />
        <a href="mailto:mike@luxvera.com" className={styles.contactButton}>
          Get in Touch
        </a>
        <Divider />
        <Clock />
      </div>
    </Layout>
  );
};

export default Home;

