import React from 'react';
import Layout from '../../common/Layout/Layout';
import ProjectCard from './ProjectCard';
import useFadeEffect from '../../hooks/useFadeEffect';
import styles from './Projects.module.css';

const Projects = () => {
  const isVisible = useFadeEffect();
  const projects = [
    {
      title: "Declarative Operating System",
      description: "Multi-host NixOS configuration with automated tests and Docker integration.",
      technologies: ["NixOS", "Docker", "DevOps", "CI/CD"],
      link: "https://github.com/mardukpill/dotties"
    },
    {
      title: "Azure Dynamic Motor Controllers",
      description: "Reverse engineered protocol of motor controller increasing power output by 116% of electric-converted Mazda Miata.",
      technologies: ["Python", "CAN Bus", "Serial Interface", "Reverse Engineering"],
      link: "https://github.com/mardukpill/Azure-Dynamics-DMOC-X45"
    },
    {
      title: "HowAreU",
      description: "Mood tracking app developed in 24 hours for a hackathon using Expo and React Native.",
      technologies: ["React Native", "Expo", "SQLite", "Android", "iOS"],
      link: "https://mardu.cc/"
    },
    {
      title: "mardu.cc",
      description: "Personal portfolio website.",
      technologies: ["Node.js", "React", "CSS"],
      link: "https://mardu.cc/"
    }
  ];

  return (
    <Layout>
      <div className={`${styles.projectsContent} fade-effect ${isVisible ? 'visible' : ''}`}>
        <h2>Projects</h2>
        <div className={styles.projectsGrid}>
          {projects.map((project, index) => (
            <ProjectCard key={index} {...project} />
          ))}
        </div>
      </div>
    </Layout>
  );
};

export default Projects;
