import React from 'react';
import Header from '../../common/Header/Header';
import styles from './Playground.module.css';

const Playground = () => {
  return (
    <div className={styles.playgroundPage}>
      <Header />
      <main className={styles.main}>
      </main>
    </div>
  );
};

export default Playground;
