import React from 'react';
import { Link } from 'react-router-dom';
import styles from './HeaderLink.module.css';

const HeaderLink = ({ to, icon, text, external, size = 'medium' }) => {
  const LinkComponent = external ? 'a' : Link;
  const linkProps = external ? { href: to, target: "_blank", rel: "noopener noreferrer" } : { to };

  return (
    <LinkComponent {...linkProps} className={`${styles.headerLink} ${styles[size]}`}>
      {icon && <img src={icon} alt={`${text} icon`} className={styles.icon} />}
      <span className={styles.text}>{text}</span>
    </LinkComponent>
  );
};

export default HeaderLink;
