import React from 'react';
import styles from './ProjectCard.module.css';

const ProjectCard = ({ title, description, technologies, link }) => (
  <div className={styles.projectCard}>
    <h3>{title}</h3>
    <p>{description}</p>
    <div className={styles.technologies}>
      {technologies.map((tech, index) => (
        <span key={index} className={styles.tech}>{tech}</span>
      ))}
    </div>    
    {link && <a href={link} target="_blank" rel="noopener noreferrer">View Project</a>}
  </div>
);

export default ProjectCard;
