import { useState, useEffect } from 'react';

const useFadeEffect = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    setIsVisible(false);
    const timer = setTimeout(() => setIsVisible(true), 50);
    return () => clearTimeout(timer);
  }, []);

  return isVisible;
};

export default useFadeEffect;

